/* --------- Palvelut component ------- */

.Services {
  min-height: 50vh;
  padding: 3rem 5% 1rem 5%;
}

.Otsikko {
  text-align:center;
  text-transform: uppercase;
}

.kuva{
  height: 100px;
  width: 150px;
}

.kuva1{
  height: 100px;
  width: 100px;
}

.kuva2{
  height: 100px;
  width: 130px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  padding: 2rem;
}

.grid-container > div {
  text-align: center;
  padding: 20px 0 50px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
}

.grid-container > div > p {
  position: absolute;
  transform: translateY(70px);
}

.buttonfoundhere {
  display: flex;
  justify-content: center;
}

.simsalabimnappi {
  margin: 1rem;
  padding: 1rem 2rem;
  font-size: 16px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  border: none;
  border-radius: 0.5rem;
  background-color: #b01f2bdb;
  transition: all .3s ease-in-out;
  text-decoration: none;
  color: #fff;
}

.simsalabimnappi:hover{
  color: #f1f1f1;
  background-color: #79161fdb;
  transition: all .3s ease-in-out;
  scale: 1.1;
}

.simsalabimnappi a{
  text-decoration: none;
  color: #fff;
}

.galleryHideHelper {
  position: relative;
  overflow: hidden;
  height: 534px;
}

.showGallery {
  transform: translateY(-534px);
  transition: transform 0.6s ease;
  position: absolute;
  justify-content: center;
  width: 100%;
}

.toggled {
  transform: translateY(0%);
  transition: transform 0.6s ease 0s;
}

.alaosaupwards {
  margin-top: -534px;
  transition: margin-top 0.6s ease;
  position: relative;
}

.toggledalaosa {
  margin-top: 0px;
  transition: margin-top 0.6s ease;
  position: relative;
}

.p{
  font-size: 30px;
}

@media(max-width: 1200px) {
  .grid-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  padding: 10px;
}
}

@media(max-width: 720px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto;
    gap: 10px;
    padding: 10px;
  }
  .Otsikko {
    text-align:center;
    padding-bottom: 10rem;
  }
  .p{
    font-size: 15px;
  }
  .galleryHideHelper {
    height: 384px;
  }
  .showGallery {
  transform: translateY(-384px);
  }
  .toggled {
  transform: translateY(0%);
  }
  .alaosaupwards {
    margin-top: -384px;
  }
}

