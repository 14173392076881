.about-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 60vh;
    padding: 4rem;
    margin: 4rem;
    gap: 3rem;
  }
  
  .image-section {
    flex: 1.5;
  }
  
  .image-section img {
    width: 90%;
  }
  
  .about-section {
    flex: 1;
  }

  .about-section p {
    padding: 1rem;
    font-size: 20px;
  }
  
  .about-section h2{
    font-size: 50px;
    padding: 1rem;
    text-transform: uppercase;
  }
  
  @media screen and (max-width: 1400px) {
    .about-section h2 {
      font-size: 50px;
    }
  }
  
  @media screen and (max-width: 1150px) {
    .about-container {
      flex-direction: column;
    }
    .image-section img { 
      width: 100%;
      padding: 1rem;
    }
    .image-section {
      width: 100%;
    }
    .about-section h2 {
      padding: 1rem;
      font-size: 60px;
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    .about-container {
      flex-direction: column;
      padding: .5rem;
      margin: .5rem;
      gap: 0rem;
    }
    .about-section h2 {
      font-size: 40px;
    }
    .about-section p {
      font-size: 16px;
    }
  }
  @media (max-width: 500px) {
    .about-section h2 {
      font-size: 30px;
    }
  }
