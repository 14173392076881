/* --------- Asiakkaat component ------- */

.Clients {
  padding: 3rem 1rem 0rem;
}

.Otsikko {
  text-align: center;
  padding: 3rem;
  font-size: 50px;
}

.miniotsikko {
  text-align: center;
  font-size: 1.5em;
}

.flex-this {
  text-align: center;
  padding: 20px;
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

img.clientpic{
  max-height: 100px;
  padding-left: 3rem;
  padding-right: 3rem;
}

.eierotetajosmahd {
  display: block;
  white-space: nowrap;
}

.asiakaslogo {
  display: inline-block;
}

@media (max-width: 720px) {
  .eierotetajosmahd{
    display: inline;
}
.Otsikko {
  padding: 2rem;
  font-size: 40px;
}
img.clientpic{
  max-height: 80px;
}
}

@media (max-width: 500px) {
.eierotetajosmahd {
  white-space: normal;
}
.Otsikko {
  padding: 0;
  font-size: 30px;
}
img.clientpic{
  max-height: 60px;
}
}