/* --------- kaavaketta component ------- */

.Contact {
  min-height: 60vh;
  background-color: #252525;
  padding: 1rem 6% 1rem 6%;
  display: flex;
  flex-direction: column;
}

.Otsikko-Contact {
  text-align: center;
  padding: 30px;
  padding-top: 70px;
  padding-bottom: 50px;
  color: white;
  font-size: 50px;
}

.riville-container {
  color: white;
  text-decoration: none;
  white-space: nowrap;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item1 {
  padding-top: 20px;
  padding-right: 10px;
  padding-bottom: 40px;
  padding-left: 5%;
  flex: 50%;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: left;
}

.flex-item1 p {
  max-width: 30rem;
}

.ppp{ 
  font-size: 30px;
  line-height: 1.6;
  margin-bottom: 1rem;
  margin-left: 2rem;
  color: white;
}

.flex-item2 {
  padding-top: 10px;
  padding-right: 30px;
  padding-bottom: 40px;
  padding-left: 5px;
  flex: 50%;
  display: flex;
  justify-content: left;
}

.flex-item2 button {
  border: none;
  background-color: transparent;
}

.flex-item2-contents {
  padding-left: 10%;
}

.riville-container a {
  display: inline;
  padding: 10px;
  font-size: 35px;
  color: white;
  text-decoration: none;
  line-height: 2.2;
  border-radius: 15px;
  user-select: text; 
}

.riville-container{
  white-space: nowrap;
  transition-duration: 0.2s;
}

.riville-container:hover{
  scale: 1.1;
  transition-duration: 0.4s;
}

.riville-container a:hover{
  transition-duration: 0.4s;
  border: 2px solid white;
}

.riville-container a:hover .fapic{
  transition-duration: 0.4s;
}

.fapic {
  font-size: 40px;
  color: white;
  padding-right: 20px;
}

.alaicon{
  font-size: 50px;
  color: white;
  padding: 10px;
  transition: all .3s ease-in-out;
}

.alaicon:hover {
  scale: 1.1;
}

.rivitetty-container {
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 6rem;
  position: relative;
  margin-top: auto;
}

.DynamiittiOrava {
  display: static;
  padding-left: 2rem;
  color: white;
  left: 0rem;
  bottom: 1rem;
  position: absolute;
}

.DynamiittiOrava img {
  width: 35px;
  height: 35px;
  padding: 5px;
  margin-top: 12px;
  transform: translateY(12px);
}

.testi{
  font-size: 20px;
}

/* Responsive layout*/
@media (max-width: 1600px) {
  .Contact {
    padding: 1rem 3% 1rem 3%;
  }
  .flex-item2{
      padding-left: 1rem;
  }
}

@media (max-width: 1550px) {
  .flex-item1{
    padding-left: 0;
  }
  .flex-item2-contents {
    padding-left: 0;
  }
}  

@media (max-width: 1268px) {
  .flex-container {
    flex-direction: column-reverse;
  }
  .flex-item1, .flex-item2{
    flex: 100%;
  }
  .flex-item1, .flex-item2-contents{
    padding-left: 2rem;
  }
}

@media (max-width: 700px) {
  .flex-item2-contents {
    padding-left: 0;
    max-width: 400px;
  }
  .flex-item1 {
    padding-left: 0;
  }

  .testi{
    font-size: 15px;
  }
}

@media (max-width: 570px) {
  .riville-container a {
    font-size: 30px;
  }
}

@media (max-width: 490px) {
  .riville-container a {
      font-size: 20px;
  }
  .fapic {
        font-size: 20px;
        padding-right: 5px;
  }
  .Otsikko-Contact {
        font-size: 30px;
  }
  .ppp{ 
    font-size: 20px;
  }
}

@media (max-width: 330px) {
.flex-item2{
    padding-left: 0;
}
}
