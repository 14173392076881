/* -------- Nav -------- */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  min-height: 10vh;
  padding: 3rem 5rem;
}

.logo {
  display: flex;
  justify-content: center;
  width: 200px;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.nav-links li{
  display: inline-block;
  list-style: none;
  letter-spacing: .1rem;
  transition: all .3s ease-in-out;
}

.nav-links a {
  text-decoration: none;
  color: #f1f1f1;
}

.nav-links li:hover {
  scale: 1.1;
}

.socmedia {
  display: flex;
  gap: 2rem;
  transition: all .3s ease-in-out;
}

/* -------- Burger menu -------- */

.burger-menu {
  display: none;
  cursor: pointer;
}

.burger-menu div {
  width: 2.5rem;
  height: .2rem;
  margin: .4rem;
  border-radius: 1rem;;
  background-color: #ececec;
  transition: all 0.5s ease-in;
}

@media screen and (max-width: 1150px) {
  body {
    overflow-x: hidden;
  }
  .burger-menu {
    display: block;
    z-index: 2;
  }
  .navbar {
    padding: 3rem 3rem;
  }

  .nav-links {
    position: fixed; 
    font-size: 30px;
    top: 0vh;
    right: 0px;
    height: 100vh;
    width: 100%;
    background-color: #222222;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center; 
    justify-content: center;
    transform: translateX(100%);
    transition: transform 0.1s step-end;
    z-index: 1;
  }

  .logo {
    z-index: 3;
  }
}

/* -------- Animation ------- */

.nav-active {
  transform: translateX(0%);
  transition: transform 0.5s ease-in;
}

/* -------- Toggle animation -------- */

.toggle .line1 {
  transform: rotate(-45deg) translate(-6px, 7px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-6px, -7px); 
}

/* -------- Portrait mode -------- */

@media screen and (max-height:1150px) and (orientation: portrait) {
  .nav-links {
    font-size: 35px;
    gap: 3rem;
  }
  .logo {
    width: 200px;
  }
}

@media screen and (max-height: 768px) and (orientation: portrait) {
  .navbar {
    padding: 2rem 2rem;
  }
  .nav-links {
    font-size: 22px;
  }
  .logo {
    width: 170px;
  }
}

@media screen and (max-height: 568px) and (orientation: portrait) {
  .nav-links {
    font-size: 16px;
    gap: 2rem;
  }
}

/* ----- Landscape mode ------- */

@media screen and (max-width: 768px) and (orientation: landscape) {
  .nav-links {
    font-size: 20px;
    gap: 2rem;
  
  }
  .navbar {
    padding: 2rem 2rem;
  }
  .logo {
    width: 200px;
  }
}

@media screen and (max-width: 568px) and (orientation: landscape) {
  .nav-links {
    font-size: 16px;
    gap: 1.5rem;
  }
  .logo {
    width: 165px;
  }
}
