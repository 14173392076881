/* --------- Gallery component ------- */

.carousel .slide {
    max-width: 800px; 
}

.carousel .slide img {
    max-height: 400px;
    width: auto;
}

.carousel .thumb {
    justify-content: center;
    align-items: center;
    max-width: 74px;
}

.carousel .thumbs img {
    height: 60px;
    align-self: center;
}

@media(max-width: 720px) {
    .carousel .slide {
        max-width: 600px; 
    }
    .carousel .thumbs img {
        height: 60px;
        align-self: center;
    }
    .carousel .slide img {
        max-height: 250px;
        width: auto;
    }
  }
