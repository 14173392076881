/* -------- Front page styling -------- */

.frontpage {
  background-color: #252525;
  min-height: 100vh;
  position: relative;
}

.frontpage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 12rem;
  width: 60%;
}

.frontpage-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.frontpage-image img {
  width: 100%;
}

.frontpage-title {
  text-align: center;
}

.frontpage-title h1 {
  font-size: 60px;
  text-transform: uppercase;
  padding: 1rem 0rem;
  color: #f1f1f1;
}

.frontpage-title h3 {
  font-size: 30px;
  color: #f1f1f1;
}

.ctas {
  text-align: center;
  margin-top: 1rem;
}

.cta-main {
  margin: 1rem;
  padding: 1rem 2rem;
  font-size: 16px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  border: none;
  border-radius: 0.5rem;
  background-color: #b01f2bdb;
  transition: all .3s ease-in-out;
  text-decoration: none;
  color: #fff;
}

.cta-main:hover{
  color: #f1f1f1;
  transition: all .3s ease-in-out;
  scale: 1.05;
  cursor: pointer;
}

.cta-main:active {
  scale: 0.8;
}

/* -------- Mobile/Tablet responsive -------- */

@media screen and (max-width: 1150px) and (orientation: portrait) {
  .frontpage-container {
    flex-direction: column-reverse;
    max-height: 50vh;
    gap: 2rem;
    width: 40%;
  }
  .frontpage-title h1 {
    font-size: 60px;
  }
  .frontpage-title h3 {
    font-size: 30px;
  }
}

@media screen and (max-height: 736px) and (orientation: portrait) {
  .frontpage-title h1 {
    font-size: 40px;
  }
  .frontpage-title h3 {
    font-size: 20px;
  }
  .cta-main {
    font-size: 10px;
  }
}

@media screen and (max-height: 568px) and (orientation: portrait) {
  .frontpage-container {
    gap: 1rem;
  }
  .frontpage-title h1 {
    font-size: 30px;
  }
  .frontpage-title h3 {
    font-size: 16px;
  }
  .cta-main {
    padding: 1rem 1rem;
  }
}

@media screen and (max-height: 530px) and (orientation: portait) {
  .frontpage-title h1 {
    font-size: 22px;
  }
  .frontpage-title h3 {
    font-size: 12px;
  }
}

/* ------- Horizontal mobile responsive ------- */


@media screen and (max-width: 1150px) and (orientation: landscape) {
  .frontpage-container {
    gap: 10rem;
  }
  .frontpage-title h1 {
    font-size: 45px;
  }
  .frontpage-title h3 {
    font-size: 20px;
  }
  .cta-main {
    font-size: 13px;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .frontpage-container {
    gap: 10rem;
  }
}

@media screen and (max-width: 926px) and (orientation: landscape) {
  .frontpage-container {
    gap: 7rem;
  }
  .frontpage-title h1 {
    font-size: 32px;
  }
  .frontpage-title h3 {
    font-size: 24px;
  }
  .cta-main {
    font-size: 11px;
  }
}


@media screen and (max-width: 768px) and (orientation: landscape) {
  .frontpage-container {
    padding-top: 2rem;
    width: 70%;
  }
  .frontpage-title h1 {
    font-size: 26px;
  }
  .frontpage-title h3 {
    font-size: 18px;
  }
  .cta-main {
    margin: .3rem;
    padding: .75rem .45rem;
  }
}

@media screen and (max-width: 568px) and (orientation: landscape) {
  .frontpage-container {
    gap: 5rem;
    padding-top: 2rem;
  }
  .frontpage-title h1 {
    font-size: 22px;
  }
  .frontpage-title h3 {
    font-size: 12px;
  }
  .cta-main {
    margin: 0;
    padding: .7rem .14rem;
  }
}
